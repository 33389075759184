import moment from "moment";
import 'moment-timezone'

export const eventTimezoneName = 'Europe/Berlin'
export const getTimezoneOffest = () => {
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const eventTimeOffset = (moment.tz(eventTimezoneName) as unknown as { _offset: number })._offset
    const localTimeOffset = localTimezoneName ? (moment.tz(localTimezoneName) as unknown as { _offset: number })._offset : moment(moment.tz(moment.tz.guess()).format()).utcOffset()
  
    let timezoneMinutesDifference = Math.abs(eventTimeOffset - localTimeOffset)
  
    if (eventTimeOffset > localTimeOffset)
      timezoneMinutesDifference = -timezoneMinutesDifference
  
    return timezoneMinutesDifference
  }