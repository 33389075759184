import { State } from "@hookstate/core";
import { Validation } from "@hookstate/validation";
import { ServiceKeys } from "./ConfigDataState";
import { Coupons, EventDate, ExhibitorData, Link, Media, News, Person, Product, Showrooms, Trademark, Job, PrintCatalog, countryHasZipCode } from "./ExhibitorDataState";
import branding from "../branding/branding"
//const PHONE_REGEX = new RegExp('^[\\d+-// ]*$')
import moment from 'moment';
import { compareDates, now } from '../utils/Dates';
import { hasServiceKeys } from '../globalStates/ConfigDataState';

const EMAIL_REGEX = new RegExp("^[A-Za-z0-9](.*)([@]{1})(.{1,})(\\.)(.{1,})")

const URL_REGEX = new RegExp('^((ft|htt)ps?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i')

// const DATETIME_REGEX = new RegExp("(19|20)[0-9]{2}-(0|1)[0-9]-[0-3][0-9] 0[0-9]|1[0-9]|2[0123]:[012345][0-9]:[012345][0-9]")

const isNotMonoLingual = !branding.configuration.monoLingual

export function addValidators(exhibitorDatastate: State<ExhibitorData, Validation>, serviceKeys: ServiceKeys) {
    exhibitorDatastate.organization.postCode.validate((postCode: string) => !countryHasZipCode(exhibitorDatastate.organization.countryCode.get()) || (!!postCode && postCode.length > 0), 'missingCompanyPostcode')

    exhibitorDatastate.organization.postCode.validate((postCode: string) => !countryHasZipCode(exhibitorDatastate.organization.countryCode.get()) || (!!postCode && postCode.length > 0), 'missingCompanyPostcode')
    exhibitorDatastate.organization.city.validate((city: string) => !!city && city.length > 0, 'missingCompanyCity')
    exhibitorDatastate.organization.countryCode.validate((countryCode: string) => !!countryCode && countryCode.length > 0, 'missingCompanyCountry')

    if (branding.configuration.profilePage.sctBasicInfoMandatory.companyNameMandatory) {
        exhibitorDatastate.organization.name.validate((name: string) => !!name && name.length > 0, 'missingCompanyName')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.phoneNumberMandatory) {
        exhibitorDatastate.organization.phone.validate((phone: string) => !!phone && phone.length > 0, 'missingCompanyPhone')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.mobileNumberMandatory) {
        exhibitorDatastate.organization.mobile.validate((mobile: string) => !!mobile && mobile.length > 0, 'missingCompanyMobile')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.faxMandatory) {
        exhibitorDatastate.organization.fax.validate((fax: string) => !!fax && fax.length > 0, 'missingCompanyFax')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.emailMandatory) {
        exhibitorDatastate.organization.email.validate((email: string) => !!email && email.length > 0, 'missingCompanyEmail')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.websiteMandatory) {
        exhibitorDatastate.organization.web.validate((web: string) => !!web && web.length > 0, 'missingCompanyWebsite')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.address1Mandatory) {
        exhibitorDatastate.organization.address1.validate((address1: string) => !!address1 && address1.length > 0, 'missingCompanyAddress')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.address2Mandatory) {
        exhibitorDatastate.organization.address2.validate((address2: string) => !!address2 && address2.length > 0, 'missingCompanyAddress')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.address3Mandatory) {
        exhibitorDatastate.organization.address3.validate((address3: string) => !!address3 && address3.length > 0, 'missingCompanyAddress')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.zipCodeMandatory) {
        exhibitorDatastate.organization.postCode.validate((postCode: string) => !!postCode && postCode.length > 0, 'missingCompanyPostcode')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.cityMandatory) {
        exhibitorDatastate.organization.city.validate((city: string) => !!city && city.length > 0, 'missingCompanyCity')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.countryMandatory) {
        exhibitorDatastate.organization.countryCode.validate((countryCode: string) => !!countryCode && countryCode.length > 0, 'missingCompanyCountry')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.companySizeMandatory) {
        exhibitorDatastate.organization.size.validate((size: string) => !!size && size.length > 0, 'missingCompanySize')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.industryMandatory) {
        exhibitorDatastate.organization.industry.validate((industry: string) => !!industry && industry.length > 0, 'missingCompanyIndustry')
    }
    if (branding.configuration.profilePage.sctBasicInfoMandatory.foundingYearMandatory) {
        exhibitorDatastate.organization.foundingYear.validate((foundingYear: string) => !!foundingYear && foundingYear.length > 0, 'missingCompanyFoundingYear')
    }

    //exhibitorDatastate.organization.mobile.validate((mobile: string | undefined) => !mobile || PHONE_REGEX.test(mobile), 'Please enter a valid mobile number')
    //exhibitorDatastate.organization.phone.validate((phone: string | undefined) => !phone || PHONE_REGEX.test(phone), 'Please enter a valid phone number')
    //exhibitorDatastate.organization.fax.validate((fax: string | undefined) => !fax || PHONE_REGEX.test(fax), 'Please enter a valid fax number')
    exhibitorDatastate.organization.email.validate((email: string | undefined) => !email || EMAIL_REGEX.test(email), 'invalidCompanyEmail')
    exhibitorDatastate.organization.email2.validate((email2: string | undefined) => !email2 || EMAIL_REGEX.test(email2), 'invalidCompanyEmail2')
    if (hasServiceKeys(serviceKeys.link)) {
        addValidatorsLinks(exhibitorDatastate.organization.links)
    }
    if (hasServiceKeys(serviceKeys.products)) {
        addValidatorsProducts(exhibitorDatastate.organization.products)
    }
    if (hasServiceKeys(serviceKeys.trademarks)) {
        addValidatorsTrademarks(exhibitorDatastate.organization.trademarks)
    }
    if (hasServiceKeys(serviceKeys.contactPartner)) {
        addValidatorsTeams(exhibitorDatastate.organization.persons)
    }
    if (hasServiceKeys(serviceKeys.multimedia)) {
        addValidatorsMultimedias(exhibitorDatastate.organization.medias)
    }
    if (hasServiceKeys(serviceKeys.news)) {
        addValidatorsNewsList(exhibitorDatastate.organization.newsList)
    }

    const eventDatesVisible = (hasServiceKeys(serviceKeys.eventDate)
        || hasServiceKeys(serviceKeys.eventDateMasterClass)
        || hasServiceKeys(serviceKeys.eventDateRemoteLiveStream)
        || hasServiceKeys(serviceKeys.eventDatePreRecorded)
        || hasServiceKeys(serviceKeys.eventDateRoundTable)
    )

    if (eventDatesVisible) {
        addValidatorsEventDates(exhibitorDatastate.organization.eventDates)
    }
    if ((serviceKeys.showroomPublic !== undefined && hasServiceKeys(serviceKeys.showroomPublic)) || (serviceKeys.showroomPrivate !== undefined && hasServiceKeys(serviceKeys.showroomPrivate))) {
        addValidatorsShowrooms(exhibitorDatastate.organization.showrooms)
    }

    const hasServiceKeyGoodies = hasServiceKeys(serviceKeys.goodies)
    if (hasServiceKeyGoodies) {
        addValidatorsCoupons(exhibitorDatastate.organization.goodies)
    }
    const hasServiceKeyJobAd = hasServiceKeys(serviceKeys.jobAd)
    if (hasServiceKeyJobAd) {
        // addValidatorsJobs(exhibitorDatastate.organization.jobOffers)
        addValidatorsJobs(exhibitorDatastate.organization.jobOffers)
    }
    const printVisible =
        hasServiceKeys(serviceKeys.printOrganizationName)
        || hasServiceKeys(serviceKeys.printAddress)
        || hasServiceKeys(serviceKeys.printContact)
        || hasServiceKeys(serviceKeys.printLogo)
        || hasServiceKeys(serviceKeys.printLogoExhibitorDirectory)
        || hasServiceKeys(serviceKeys.printLogoCategoryDirectory)
        || hasServiceKeys(serviceKeys.printDescription)
        || hasServiceKeys(serviceKeys.printAd)

    const deadline = branding.configuration.printPage.deadLine && moment(branding.configuration.printPage.deadLine).toDate()
    const printCatalogReadOnly = !!(deadline && (compareDates(deadline, now()) < 0))

    if (printVisible && !printCatalogReadOnly) {
        addValidatorsPrintCatalog(exhibitorDatastate.organization.printCatalog, serviceKeys)
    }
}

function addValidatorsLinks(links: State<Link[], Validation>) {
    links.forEach(link => {
        addValidatorsLink(link)
    })
}

export function addValidatorsLink(link: State<Link, Validation>) {
    isNotMonoLingual && link.text.validate((text: string) => !!text && text.length > 0, 'missingLinkName')
    link.textDe.validate((text: string) => !!text && text.length > 0, 'missingLinkName')
    isNotMonoLingual && link.url.validate((url: string) => !!url && url.length > 0, 'missingLinkUrl')
    link.urlDe.validate((url: string) => !!url && url.length > 0, 'missingLinkUrl')
}

function addValidatorsProducts(products: State<Product[], Validation>) {
    products.forEach(product => {
        addValidatorsProduct(product)
    })
}

export function addValidatorsProduct(product: State<Product, Validation>) {
    isNotMonoLingual && product.name.validate((name: string) => !!name && name.length > 0, 'missingProductName')
    product.nameDe.validate((name: string) => !!name && name.length > 0, 'missingProductName')
    // product.showFrom.validate((date: Date | undefined) => {
    //     // console.log("validate product.showFrom", date)
    //     return !date || (compareDates(startOfDay(date), startOfDay(now())) >= 0)
    // }, 'dateGreaterOrEqualThanCurrentDate')
    // product.showUntil.validate((date: Date | undefined) => {
    //     // console.log("validate product.showUntil", date)
    //     return !date || (compareDates(startOfDay(date), startOfDay(now())) > 0)
    // }, 'dateGreaterThanCurrentDate')
    product.validate((currentProduct: Product) => {
        // console.log(product, currentProduct)
        const date1GraterThanDate2 = compareDates(currentProduct.showUntil, currentProduct.showFrom) > -1
        return date1GraterThanDate2
    }, 'invalidProductPublicationEndDate')

    addValidatorsMultimedias(product.medias)
}

function addValidatorsTrademarks(trademarks: State<Trademark[], Validation>) {
    trademarks.forEach(trademark => {
        addValidatorsTrademark(trademark)
    })
}

export function addValidatorsTrademark(trademark: State<Trademark, Validation>) {
    trademark.name.validate((name: string) => !!name && name.length > 0, 'missingTrademarkName')
    addValidatorsMultimedias(trademark.medias)
}

function addValidatorsTeams(persons: State<Person[], Validation>) {
    persons.forEach(person => {
        addValidatorsPerson(person)
    })
}

export function addValidatorsPerson(person: State<Person, Validation>) {
    person.firstName.validate((firstName: string) => !!firstName && firstName.length > 0, 'missingFirstName')
    person.lastName.validate((lastName: string) => !!lastName && lastName.length > 0, 'missingLastName')
    person.email.validate((email: string) => !!email && email.length > 0, 'missingEmail')
    person.email.validate((email: string) => !email || EMAIL_REGEX.test(email), 'invalidEmail')
    person.function.validate((role: string) => !!role && role.length > 0, 'missingRole')
}

function addValidatorsMultimedias(medias: State<Media[], Validation>) {
    medias.forEach(media => {
        addValidatorsMultimedia(media)
    })
}

export function addValidatorsMultimedia(media: State<Media, Validation>) {
    isNotMonoLingual && media.title.validate((title: string) => !!title && title.length > 0, 'missingMediaTitle')
    media.titleDe.validate((title: string) => !!title && title.length > 0, 'missingMediaTitle')
}

function addValidatorsNewsList(newsList: State<News[], Validation>) {
    newsList.forEach(news => {
        addValidatorsNews(news)
    })
}

export function addValidatorsNews(news: State<News, Validation>) {
    isNotMonoLingual && news.name.validate((name: string) => { return !!name && name.length > 0 }, 'missingNewsTitle')
    news.nameDe.validate((name: string) => !!name && name.length > 0, 'missingNewsTitle')
    news.date.validate((date: Date | undefined) => !!date, 'missingNewsDate')
    addValidatorsMultimedias(news.medias)
}

function addValidatorsEventDates(eventDates: State<EventDate[], Validation>) {
    eventDates.forEach(eventDate => {
        addValidatorsEventDate(eventDate)
    })
}

export function addValidatorsEventDate(eventDate: State<EventDate, Validation>) {
    isNotMonoLingual && eventDate.name.validate((name: string) => !!name && name.length > 0, 'missingEventDateTitle')
    eventDate.nameDe.validate((name: string) => !!name && name.length > 0, 'missingEventDateTitle')
    addValidatorsTeams(eventDate.persons)
    addValidatorsMultimedias(eventDate.medias)
}

function addValidatorsShowrooms(showrooms: State<Showrooms[], Validation>) {
    showrooms.forEach(showroom => {
        addValidatorsShowroom(showroom)
    })
}

export function addValidatorsShowroom(showroom: State<Showrooms, Validation>) {
    isNotMonoLingual && showroom.title.validate((title: string) => !!title && title.length > 0, 'missingShowroomTitle')
    showroom.titleDe.validate((title: string) => !!title && title.length > 0, 'missingShowroomTitle')
}

function addValidatorsCoupons(coupons: State<Coupons[], Validation>) {
    coupons.forEach(coupon => {
        addValidatorsCoupon(coupon)
    })
}

export function addValidatorsCoupon(coupon: State<Coupons, Validation>) {
    coupon.name.validate((name: string) => !!name && name.length > 0, 'missingCouponName')
    coupon.validStart.validate((date: Date | undefined) => !!date, 'missingValidStartDate')
    coupon.validEnd.validate((date: Date | undefined) => !!date, 'missingValidEndDate')
    coupon.url.validate((url: string) => !url || URL_REGEX.test(url), 'missingCouponValidUrl')
    coupon.urlDe.validate((urlDe: string) => !urlDe || URL_REGEX.test(urlDe), 'missingCouponValidUrl')
}

function addValidatorsJobs(jobs: State<Job[], Validation>) {
    jobs.forEach(job => {
        addValidatorsJob(job)
    })
}

export function addValidatorsJob(job: State<Job, Validation>) {
    isNotMonoLingual && job.title.validate((title: string) => !!title && title.length > 0, 'missingJobTitle')
    job.titleDe.validate((titleDe: string) => !!titleDe && titleDe.length > 0, 'missingJobTitle')
    job.startdate.validate((date: Date | undefined) => !!date, 'missingJobValidStartDate')
    job.showFrom.validate((date: Date | undefined) => !!date, 'missingJobValidStartDisplaying')
    addValidatorsTeams(job.persons)
    addValidatorsMultimedias(job.medias)
}

export function addValidatorsPrintCatalog(printCatalog: State<PrintCatalog, Validation>, serviceKeys: ServiceKeys) {
    if (hasServiceKeys(serviceKeys.printOrganizationName)) {
        printCatalog.name.validate((companyName: string) => !!companyName && companyName.length > 0, 'missingPrintCompanyName')
    }
    if (hasServiceKeys(serviceKeys.printAddress) || hasServiceKeys(serviceKeys.printAddressCategoryDirectory)) {
        printCatalog.address1.validate((address: string) => !!address && address.length > 0, 'missingPrintAddress')
        printCatalog.postcode.validate((postcode: string) => !countryHasZipCode(printCatalog.countryCode.get()) || (!!postcode && postcode.length > 0), 'missingPrintZipcode')
        printCatalog.city.validate((city: string) => !!city && city.length > 0, 'missingPrintCity')
        printCatalog.countryCode.validate((country: string) => !!country && country.length > 0, 'missingPrintCountry')
    }
    if (hasServiceKeys(serviceKeys.printContact) || hasServiceKeys(serviceKeys.printContactCategoryDirectory)) {
        printCatalog.email.validate((email: string | undefined) => !email || EMAIL_REGEX.test(email), 'invalidEmail')
    }
}
