interface xhrRequestOptions {
    body?: string | FormData | undefined
    headers?: string | undefined
    method: string;
    onProgress?: (progress: number) => void
}

export function xhr(path: string, reqOptions: xhrRequestOptions) {
    return new Promise<XMLHttpRequest>((resolve, reject) => {
        const req = new XMLHttpRequest()
        req.open(reqOptions.method, path, true)
        req.onload = function () {
            if (req.readyState === 4) {
                resolve(req)
            } else {
                reject(Error(req.statusText))
            }
        }
        req.upload.onprogress = function (event) {
            if (event.lengthComputable) {
                reqOptions.onProgress?.(event.loaded / event.total * 100)
            }
        }
        req.onprogress = function (event) {
            if (event.lengthComputable) {
                reqOptions.onProgress?.(event.loaded / event.total * 100)
            }
        }
        req.onerror = function (event) {
            reject("Network error")
        }
        try {
            req.send(reqOptions.body)
        } catch (error) {
            reject(Error("Network Error"))
        }
    })
}