import moment from 'moment';

const ISO_FORMAT = "yyyy-MM-DD HH:mm:ss"
const Y2d_FORMAT = "DD.MM.yyyy"
const Y2m_FORMAT = "DD.MM.yyyy HH:mm (z)"
const H2M_FORMAT = "HH:mm"

export function now() {
    return new Date();
}

export function currentTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function startOfDay(date: Date) {
    return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0) : date
}

export function nextDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, date.getHours(), date.getMinutes(), date.getSeconds());
}

export function nextYear(date: Date) {
    return new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
}

export function parseServerDateTime(dateTimeString: string): Date | undefined {
    return dateTimeString ? moment.utc(dateTimeString, ISO_FORMAT).local().toDate() : undefined
}

export function formatServerDateTime(dateTime: Date | undefined): string {
    return dateTime ? moment.utc(moment(dateTime)).format(ISO_FORMAT) : ''
}

export function formatDateY2d(dateTime: string | undefined): string {
    return dateTime ? moment(dateTime).format(Y2d_FORMAT) : ''
}

export function formatDateY2mz(dateTime: Date | undefined): string {
    return dateTime ? moment(dateTime).tz(moment.tz.guess()).format(Y2m_FORMAT) : ''
}

export function formatTimeIntervalHm(dateTimeFrom: string, dateTimeTo: string): string {
    const timeFrom = dateTimeFrom ? moment(dateTimeFrom).subtract({ hours: 1 }).format(H2M_FORMAT) : '';
    const timeTo = dateTimeTo ? moment(dateTimeTo).subtract({ hours: 1 }).format(H2M_FORMAT) : '';
    return `${timeFrom} - ${timeTo}`;
}

export function compareDates(date1: Date | undefined | number, date2: Date | undefined | number) {
    if (!date1) {
        return date2 ? 1 : 0
    }
    if (!date2) {
        return -1
    }

    return moment(date1).isBefore(date2) ? -1 : moment(date1).isAfter(date2) ? 1 : 0
}

export function formatDateTime(dateTime: Date | undefined | number): string {
    return dateTime ? moment(dateTime).format(ISO_FORMAT) : ''
}

export function stripEmptyString(date: Date | undefined | string) {
    return (date === '') ? null : date
}

export function dateIsAfter(date1: Date | undefined, date2: Date | undefined) {
    if (!date1) {
        return date2 ? 1 : 0
    }
    if (!date2) {
        return -1
    }

    return moment(date1).isBefore(date2, 'hour') ? -1 : moment(date1).isAfter(date2, 'hour') ? 1 : 0
}
