import { hookstate, useHookstate } from '@hookstate/core'

interface LoadingData {
    active: boolean
    message: string
}

export interface LoadingState {
    isActive: () => boolean
    setActive: (active: boolean, message?: string) => void
}

const loadingState = hookstate<LoadingData>({ active: false, message: '' })

export function useLoadingState(): LoadingState {
    const state = useHookstate(loadingState)

    return {
        isActive() {
            return state.active.get()
        },
        setActive(active: boolean, message: string = '') {
            state.active.set(active)
            state.message.set(message)
        }
    }
}
