import { hookstate, useHookstate } from '@hookstate/core'
import { fetchDataRest } from '../backendServices/backendServices'
import branding from "../branding/branding"

let initialState = {
    id: '',
    token: '',
    productTypes: {
        id: '',
        properties: [],
    },
    topicName: "",
    organizationId: ""
}

export interface ProductTypes {
    id: string
    properties: Properties[]
}

export interface Properties {
    id: string,
    name: string
    nameDe: string
    productType: string
    type: string
    unit: string
    unitDe: string
    val: string
    properties: []
}

export interface ProductTypesData {
    id: string
    token: string
    productTypes: ProductTypes
    topicName: string
    organizationId: string
}

const exhibitorDatastate = hookstate<ProductTypesData>(initialState)

async function loadProductTypesData(accessToken: string, exhibitorId: string) {
    const params: any = {
        "accessToken": accessToken,
        "organizationId": exhibitorId
    }
    return await fetchDataRest(`/selfservice/0/${branding.configuration.topicName}/producttype/list`, params, "GET")
}

export function mapProductTypes(data: any): any {
    return {
        properties: mapProperties(data.productTypes),
    }
}

function mapProperties(properties: any[]): Properties[] {
    if (!properties) return properties
    return properties.map(property => {
        return {
            ...property
        }
    })
}

export function useProductPropertiesDataState() {
    const state = useHookstate(exhibitorDatastate)

    return ({
        init(exhibitorId: string, accessToken: string) {
            const exData = loadProductTypesData(accessToken, exhibitorId)
            exData.then(
                (result) => {
                    const productTypes = mapProductTypes(result)
                    state.set({ id: exhibitorId, organizationId: exhibitorId, token: accessToken, topicName: "", productTypes: { ...productTypes } })
                    return result
                })
            return exData
        },

        reinit() {
            const exData = loadProductTypesData(state.token.get(), state.organizationId.get())
            exData.then(
                (result) => {
                    const productTypes = mapProductTypes(result)
                    state.set({ id: state.id.get(), organizationId: state.organizationId.get(), token: state.token.get(), topicName: state.topicName.get(), productTypes: { ...productTypes } })
                    return result
                })
            return exData
        },

        getProductTypes() {
            return state.productTypes
        }
    })
}
